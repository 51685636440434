import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import { Front_URL } from "./Front/constantsurl";
import { Front_API_URL } from "./Front/Constants";
import { ToastContainer, toast } from "react-toastify";

import Query from "./Query";
import "react-toastify/dist/ReactToastify.css";
export default function AboutUs() {
  const [AboutDetails, setAboutDetails] = useState([]);


  const fetchabout = async () => {
    try {
      const response = await axios.get(`${Front_API_URL}/aboutUs`);
      console.log('aboutus',response.data);
      setAboutDetails(response.data);
    } catch (error) {
      console.error("Error fetching event data", error);
    }
  };
  const parseHTML = (html) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, 'text/html');
    return doc.body.textContent || "";
  };

  useEffect(() => {
    fetchabout();
  }, []);

  return (
    <>
      {/* <!-- Start Page Title Area --> */}
      <ToastContainer />
      <div className="banner-area about">
        <div className="d-table">
          <div className="d-table-cell">
            <div className="container">
             
              <div className="page-title-content">
                <h2>About Us</h2>
                <ul>
                  <li>
                    <a href="/"> Home </a>
                    <span class="straight-line">|</span>
                    <p className="active"> About </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- End Page Title Area --> */}

      {/* <!-- About Area --> */}
      <section className="about-area">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="section-tittle">
              
                <h2>
                {AboutDetails.title ? (
                  <>
                    {String(AboutDetails.title).slice(0, 7)} <span>{String(AboutDetails.title).slice(7)}</span>
                  </>
                ) : (
                  ""
                )}
              </h2>

           
                <p>{parseHTML(AboutDetails.description)} </p>
             
              </div>
            </div>
            <div className="col-lg-6">
              <div className="about-side">
              <img src={`${Front_URL}/storage/images/${AboutDetails.image}`} alt="" />

              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- End About Area --> */}

      {/* <!-- Choose area  --> */}
      {/* <section className="choose-area">
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-lg-6 ps-0">
              <div className="home-choose-img">
                <img
                  src="assets/images/whychoose.png
                    "
                  alt="choose"
                />
              </div>
            </div>

            <div className="col-lg-6 home-choose">
              <div className="home-choose-content">
                <div className="section-tittle">
                  <h2>
                    Why Choose <span> Talentpedia</span>
                  </h2>
                  <p>
                    School choice theory assumes that parents are rational
                    actors that can gather and consume information to find a
                    school that matches their child's needs. Parents' desires
                    and ability to choose quality schools.
                  </p>
                </div>

                <div className="row">
                  <div className="col-lg-4 col-sm-12 col-md-5">
                    <ul className="choose-list-left">
                      <li>
                        <i className="flaticon-check-mark"></i>Top 10 Rated
                        School
                      </li>
                      <li>
                        {" "}
                        <i className="flaticon-check-mark"></i>Great Behaviour
                      </li>
                      <li>
                        <i className="flaticon-check-mark"></i>Helpful &
                        Kindnesss
                      </li>
                      <li>
                        <i className="flaticon-check-mark"></i>Learning With Fun
                      </li>
                      <li>
                        <i className="flaticon-check-mark"></i>Children Safety
                      </li>
                    </ul>
                  </div>
                  <div className="col-lg-8 col-sm-12 col-md-7">
                    <div className="choose-list-home">
                      <ul>
                        <li>
                          {" "}
                          <i className="flaticon-check-mark"></i>Eco Freindly
                          Environment
                        </li>
                        <li>
                          {" "}
                          <i className="flaticon-check-mark"></i>Healthy Food &
                          Water in Canteen
                        </li>
                        <li>
                          {" "}
                          <i className="flaticon-check-mark"></i>Health Care
                          With Certified Dorctors
                        </li>
                        <li>
                          <i className="flaticon-check-mark"></i>Huge Playground
                          With Children Park
                        </li>
                        <li>
                          <i className="flaticon-check-mark"></i>Physically Fit
                          Buses With Experienced Driver
                        </li>
                      </ul>
                    </div>
                  </div>
                  <a href="" className="box-btn">
                    Know More
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      {/* <!-- End Choose area --> */}

      {/* <!-- Contact Area --> */}
      {/* <Query /> */}
      
      {/* <!-- End Contact Area --> */}
    </>
  );
}
