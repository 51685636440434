import axios from "axios";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Front_API_URL } from "./Front/Constants";
import { Front_URL } from "./Front/constantsurl";

export default function Blogs() {
    const [current_page, setCurrent_page] = useState(1);
    const [links, setLinks] = useState({});
    const [blogs, setBlogs] = useState([]);

    const fetchData = async (page) => {
        try {
            const response = await axios.get(`${Front_API_URL}/allBlogsFrontend?page=${page}`);
            console.log('inspirational',response.data.data);
            setBlogs(response.data.data);
            setCurrent_page(response.data.current_page);
            setLinks(response.data.links);
        } catch (error) {
            console.error("Error fetching data", error);
        }
    };

    useEffect(() => {
        fetchData(current_page);
    }, [current_page]);

    const handlePagination = (url) => {
        if (url) {
            const page = new URL(url).searchParams.get('page');
            setCurrent_page(Number(page));
        }
    };

    const truncateString = (str, num) => {
        return str.length > num ? str.slice(0, num) + '...' : str;
    };

    const stripHtmlTags = (html) => {
        const doc = new DOMParser().parseFromString(html, 'text/html');
        return doc.body.textContent || "";
    };

    return (
        <>
            <div className="banner-area events-bg">
                <div className="d-table">
                    <div className="d-table-cell">
                        <div className="container">
                            <div className="page-title-content">
                                <h2>Inspirational Stories</h2>
                                <ul>
                                    <li>
                                        <Link to="/"> Home </Link>
                                        <span class="straight-line">|</span>
                                        <p className="active">Stories</p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <section className="events">
                <div className="container">
                    <div className="row">
                        {blogs.length > 0 ? (
                            blogs.map((item, index) => (
                                <div className="col-lg-4 col-md-6" key={index}>
                                    <div className="single-events">
                                        <div className="events-img">
                                            <Link to={`/blog-details/${item.id}`}>
                                                <img src={`${Front_URL}/storage/images/blogs/${item.image}`} alt="blogs"  />
                                            </Link>
                                        </div>
                                        <div className="content">
                                            <Link to={`/blog-details/${item.id}`}>
                                                <h2>
                                                {truncateString(stripHtmlTags(item.heading), 50)}

                                                   
                                                    </h2>
                                            </Link>
                                            <p className="event-description">
                                                {truncateString(stripHtmlTags(item.description), 106)}
                                            </p>
                                            <Link to={`/blog-details/${item.id}`} className="line-bnt read-more-btn">Read More <i className="flaticon-next"></i></Link>
                                        </div>
                                    </div>
                                </div>
                            ))
                        ) : (
                            <div className="no-data-found text-center">
                                <h3>No Blogs Found</h3>
                            </div>
                        )}
                    </div>

                    <div className="row">
                        <div className="col-lg-12 text-center">
                            <ul className="pagination">
                                {links.prev && (
                                    <li className="page-item">
                                        <a
                                            className="page-link"
                                            onClick={() => handlePagination(links.prev)}
                                        >
                                            {"<"}
                                        </a>
                                    </li>
                                )}
                                {links.next && (
                                    <li className="page-item">
                                        <a
                                            className="page-link"
                                            onClick={() => handlePagination(links.next)}
                                        >
                                            {">"}
                                        </a>
                                    </li>
                                )}
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}
