import React, { useState } from "react";
import axios from "axios";
import { Front_API_URL } from "./Front/Constants";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function SubmitCompetition() {
  const [currentStep, setCurrentStep] = useState(1);
  const [errors, setErrors] = useState({
    organiserUrl: ""
  });
  const [form, setForm] = useState({
    title: "",
    competitionImage: null,
    organiserUrl: "",
    competitionUrl: "",
    organiserName: "",
    competitionEmail: "",
    yourEmail: "",
    competitionDeadline: "",
    description: "",
    prizeType: "",
    prizeSummary: "",
    regions: [],
    education: [],
    category: [],
    boostCompetition: false,
  });


  const prizeTypeOptions = [
    { value: "cash", label: "Cash" },
    { value: "scholarship", label: "Scholarship" },
    { value: "certificate", label: "Certificate" },
    { value: "gift", label: "Gift" },
    { value: "other", label: "Other" },
  ];

  const regionOptions = [
    { value: "global", label: "Global" },
    { value: "europe", label: "Europe" },
    { value: "asia", label: "Asia" },
    { value: "africa", label: "Africa" },
    { value: "southAmerica", label: "South America" },
    { value: "northAmerica", label: "North America" },
    { value: "oceania", label: "Oceania" },
    { value: "europeanUnion", label: "European Union(UI)" },
  ];

  const educationOptions = [
    { value: "chooseAll", label: "Choose All" },
    { value: "Phd", label: "Phd" },
    { value: "High school Students", label: "High school Students" },
    { value: "Master Students", label: "Master Students" },
    { value: "Bachelor Students", label: "Bachelor Students" },
    { value: "Open to everyone", label: "Open to everyone" },
  ];

  const categoryOptions = [
    { value: "Arts/Photography", label: "Arts/Photography" },
    { value: "Design/Architecture", label: "Design/Architecture" },
    { value: "Mathematics", label: "Mathematics" },
    { value: "Law", label: "Law" },
    { value: "Fashion", label: "Fashion" },
    { value: "Education", label: "Education" },
    { value: "Natural Sciences", label: "Natural Sciences" },
    { value: "Tech & Engineering", label: "Tech & Engineering" },
    { value: "Business", label: "Business" },
    { value: "Social Sciences", label: "Social Sciences" },
    { value: "Health", label: "Health" },
  ];

  // Input change hone par ye function chalega
  const handleInput = (e) => {
    const { name, value } = e.target;

    // Form state ko update karna
    setForm((prevForm) => ({
      ...prevForm,
      [name]: value,
    }));

    // URL validate karna, chahe wo organiserUrl ho ya competitionUrl
    if (name === 'organiserUrl' || name === 'competitionUrl') {
      validateURL(name, value);
    }
  };

const validateURL = (fieldName, url) => {
    // URL pattern check karna
    const urlPattern = /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/\S*)?$/;
    if (!urlPattern.test(url)) {
      // Agar URL invalid hai toh error set karna specific field ke liye
      setErrors((prevErrors) => ({
        ...prevErrors,
        [fieldName]: 'Please enter a valid URL.',
      }));
      } else {
      // Agar URL valid hai toh error clear karna specific field ke liye
      setErrors((prevErrors) => ({
        ...prevErrors,
        [fieldName]: '',
      }));
    }
  };

  // Handle file input
  const handleFileChange = (e) => {
    setForm({
      ...form,
      competitionImage: e.target.files[0],
    });
  };

  // Handle checkbox input
  const handleCheckboxChange = (e) => {
    const { name, value, checked } = e.target;
    setForm((prevForm) => {
      const updatedArray = checked
        ? [...prevForm[name], value]
        : prevForm[name].filter((item) => item !== value);
      return { ...prevForm, [name]: updatedArray };
    });
  };

  // Move to the next step
  const nextStep = () => {
    if (validateStep()) {
      setCurrentStep(currentStep + 1);
    }
  };

  // Move to the previous step
  const prevStep = () => {
    setCurrentStep(currentStep - 1);
  };

  // Validate the current step
  const validateStep = () => {
    let errors = {};
  
    if (currentStep === 1) {
      if (!form.title?.trim()) {
        errors.title = "Title is required";
      }
      if (!form.competitionImage) {
        errors.competitionImage = "Competition image is required";
      }
  
      // URL pattern to match valid URLs with protocols
      const urlPattern = /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/\S*)?$/;
  
      if (!form.organiserUrl.trim()) {
        errors.organiserUrl = "Organizer URL is required";
      } else if (!urlPattern.test(form.organiserUrl.trim())) {
        errors.organiserUrl = "Please enter a valid Organizer URL";
      }
  
      if (!form.competitionUrl.trim()) {
        errors.competitionUrl = "Competition URL is required";
      } else if (!urlPattern.test(form.competitionUrl.trim())) {
        errors.competitionUrl = "Please enter a valid Competition URL";
      }
  
      if (!form.organiserName.trim()) {
        errors.organiserName = "Organizer name is required";
      }
    } else if (currentStep === 2) {
      if (!form.competitionEmail.trim()) {
        errors.competitionEmail = "Competition email is required";
      } else if (!/\S+@\S+\.\S+/.test(form.competitionEmail)) {
        errors.competitionEmail = "Competition email is invalid";
      }
  
      if (!form.yourEmail.trim()) {
        errors.yourEmail = "Your email is required";
      } else if (!/\S+@\S+\.\S+/.test(form.yourEmail)) {
        errors.yourEmail = "Your email is invalid";
      }
  
      if (!form.competitionDeadline.trim()) {
        errors.competitionDeadline = "Competition deadline is required";
      }
      if (!form.description.trim()) {
        errors.description = "Description is required";
      }
      if (!form.prizeType.trim()) {
        errors.prizeType = "Prize type is required";
      }
      if (!form.prizeSummary.trim()) {
        errors.prizeSummary = "Prize summary is required";
      }
    } else if (currentStep === 3) {
      if (!form.regions.length) {
        errors.regions = "At least one region is required";
      }
      if (!form.education.length) {
        errors.education = "At least one education level is required";
      }
      if (!form.category.length) {
        errors.category = "At least one category is required";
      }
    }
  
    setErrors(errors);
    return Object.keys(errors).length === 0;
  };
  

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateStep()) {
      if (currentStep === 3) {
        const formData = new FormData();
        formData.append('title', form.title);
        formData.append('competitionImage', form.competitionImage);
        formData.append('organiserUrl', form.organiserUrl);
        formData.append('competitionUrl', form.competitionUrl);
        formData.append('organiserName', form.organiserName);
        formData.append('competitionEmail', form.competitionEmail);
        formData.append('yourEmail', form.yourEmail);
        formData.append('competitionDeadline', form.competitionDeadline);
        formData.append('description', form.description);
        formData.append('prizeType', form.prizeType);
        formData.append('prizeSummary', form.prizeSummary);
        formData.append('regions', JSON.stringify(form.regions));
        formData.append('education', JSON.stringify(form.education));
        formData.append('category', JSON.stringify(form.category));
        //formData.append('boostCompetition', form.boostCompetition);

        axios
          .post(`${Front_API_URL}/submitCompetition`, formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
          .then((response) => {
            e.target.reset();
            setCurrentStep(1); // Reset to the first step if needed
            toast.success(response.data.message);
          })
          .catch((error) => {
            setErrors(error.response.data.errors);
          });
      } else {
        nextStep();
      }
    }
  };

  return (
    <>
      <ToastContainer />

      <section className="home-contact-area pb-100 pt-100">
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-lg-3 ps-0">
              {/* Additional content can be added here */}
            </div>
            <div className="col-lg-6">
              <div className="home-contact-content frm-comp">
                <h2>Step {currentStep}: {currentStep === 1 ? "Basic Information" : currentStep === 2 ? "Additional Competition Details" : "Final Details and Preferences"}</h2>
                <form id="contactForm" onSubmit={handleSubmit}>
                  {currentStep === 1 && (
                    <div className="row">
                      {/* Step 1 Fields */}
                      <div className="col-lg-6 col-sm-6">
                        <div className="form-group">
                          <input
                            type="text"
                            name="title"
                            id="title"
                            onChange={handleInput}
                            value={form.title}
                            className="form-control"
                            placeholder="Title"
                          />
                          {errors.title && <span style={{ color: "red" }}>{errors.title}</span>}
                          <div className="help-block with-errors"></div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-sm-6">
                        <div className="form-group">
                          <input
                            type="file"
                            name="competitionImage"
                            id="competitionImage"
                            onChange={handleFileChange}
                            className="form-control"
                          />
                          {errors.competitionImage && <span style={{ color: "red" }}>{errors.competitionImage}</span>}
                        </div>
                      </div>
                      <div className="col-lg-6 col-sm-6">
                        <div className="form-group">
                          <input
                            type="text"
                            name="organiserUrl"
                            id="organiserUrl"
                            onChange={handleInput}
                            value={form.organiserUrl}
                            className="form-control"
                            placeholder="Organizer URL"
                          />
                          {errors.organiserUrl && <span style={{ color: "red" }}>{errors.organiserUrl}</span>}
                        </div>
                      </div>
                      <div className="col-lg-6 col-sm-6">
                        <div className="form-group">
                          <input
                            type="text"
                            name="competitionUrl"
                            id="competitionUrl"
                            onChange={handleInput}
                            value={form.competitionUrl}
                            className="form-control"
                            placeholder="Competition URL"
                          />
                          {errors.competitionUrl && <span style={{ color: "red" }}>{errors.competitionUrl}</span>}
                        </div>
                      </div>
                      <div className="col-lg-6 col-sm-6">
                        <div className="form-group">
                          <input
                            type="text"
                            name="organiserName"
                            id="organiserName"
                            onChange={handleInput}
                            value={form.organiserName}
                            className="form-control"
                            placeholder="Organizer Name"
                          />
                          {errors.organiserName && <span style={{ color: "red" }}>{errors.organiserName}</span>}
                        </div>
                      </div>
                    </div>
                  )}
                  {currentStep === 2 && (
                    <div className="row">
                      {/* Step 2 Fields */}
                      <div className="col-lg-6 col-sm-6">
                        <div className="form-group">
                          <input
                            type="email"
                            name="competitionEmail"
                            id="competitionEmail"
                            onChange={handleInput}
                            value={form.competitionEmail}
                            className="form-control"
                            placeholder="Competition Email"
                          />
                          {errors.competitionEmail && <span style={{ color: "red" }}>{errors.competitionEmail}</span>}
                        </div>
                      </div>
                      <div className="col-lg-6 col-sm-6">
                        <div className="form-group">
                          <input
                            type="email"
                            name="yourEmail"
                            id="yourEmail"
                            onChange={handleInput}
                            value={form.yourEmail}
                            className="form-control"
                            placeholder="Your Email"
                          />
                          {errors.yourEmail && <span style={{ color: "red" }}>{errors.yourEmail}</span>}
                        </div>
                      </div>
                      <div className="col-lg-6 col-sm-6">
                        <div className="form-group">
                          <input
                            type="date"
                            name="competitionDeadline"
                            id="competitionDeadline"
                            onChange={handleInput}
                            value={form.competitionDeadline}
                            className="form-control"
                          />
                          {errors.competitionDeadline && <span style={{ color: "red" }}>{errors.competitionDeadline}</span>}
                        </div>
                      </div>
                      <div className="col-lg-6 col-sm-6">
                        <div className="form-group">
                          <textarea
                            name="description"
                            id="description"
                            onChange={handleInput}
                            value={form.description}
                            className="form-control"
                            placeholder="Description"
                          ></textarea>
                          {errors.description && <span style={{ color: "red" }}>{errors.description}</span>}
                        </div>
                      </div>
                      <div className="col-lg-6 col-sm-6">
                        <div className="form-group">
                          <select
                            name="prizeType"
                            id="prizeType"
                            onChange={handleInput}
                            value={form.prizeType}
                            className="form-control"
                          >
                            <option value="">Select Prize Type</option>
                            {prizeTypeOptions.map((option) => (
                              <option key={option.value} value={option.value}>
                                {option.label}
                              </option>
                            ))}
                          </select>
                          {errors.prizeType && <span style={{ color: "red" }}>{errors.prizeType}</span>}
                        </div>
                      </div>
                      <div className="col-lg-6 col-sm-6">
                        <div className="form-group">
                          <textarea
                            name="prizeSummary"
                            id="prizeSummary"
                            onChange={handleInput}
                            value={form.prizeSummary}
                            className="form-control"
                            placeholder="Prize Summary"
                          ></textarea>
                          {errors.prizeSummary && <span style={{ color: "red" }}>{errors.prizeSummary}</span>}
                        </div>
                      </div>
                    </div>
                  )}
      {currentStep === 3 && (
  <div className="row">
    {/* Step 3 Fields */}
    
    <div className="col-lg-12">
      {/* Regions */}
      <div className="form-group">
        <h3>Regions</h3>
        <div className="row">
          <div className="col-lg-6 col-sm-12">
            {regionOptions.slice(0, Math.ceil(regionOptions.length / 2)).map((region) => (
              <div key={region.value} className="form-check form-check-inline col-lg-12 col-sm-12">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id={region.value}
                  name="regions"
                  value={region.value}
                  checked={form.regions.includes(region.value)}
                  onChange={handleCheckboxChange}
                />
                <label className="form-check-label" htmlFor={region.value}>
                  {region.label}
                </label>
              </div>
            ))}
          </div>
          <div className="col-lg-6 col-sm-12">
            {regionOptions.slice(Math.ceil(regionOptions.length / 2)).map((region) => (
              <div key={region.value} className="form-check form-check-inline col-lg-12 col-sm-12">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id={region.value}
                  name="regions"
                  value={region.value}
                  checked={form.regions.includes(region.value)}
                  onChange={handleCheckboxChange}
                />
                <label className="form-check-label" htmlFor={region.value}>
                  {region.label}
                </label>
              </div>
            ))}
          </div>
        </div>
        {errors.regions && <span style={{ color: "red", display: "block" }}>{errors.regions}</span>}
      </div>
    </div>

    <div className="col-lg-12">
      {/* Education */}
      <div className="form-group">
        <h3>Education</h3>
        <div className="row">
          <div className="col-lg-6 col-sm-12">
            {educationOptions.slice(0, Math.ceil(educationOptions.length / 2)).map((edu) => (
              <div key={edu.value} className="form-check form-check-inline col-lg-12 col-sm-12">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id={edu.value}
                  name="education"
                  value={edu.value}
                  checked={form.education.includes(edu.value)}
                  onChange={handleCheckboxChange}
                />
                <label className="form-check-label" htmlFor={edu.value}>
                  {edu.label}
                </label>
              </div>
            ))}
          </div>
          <div className="col-lg-6 col-sm-12">
            {educationOptions.slice(Math.ceil(educationOptions.length / 2)).map((edu) => (
              <div key={edu.value} className="form-check form-check-inline col-lg-12 col-sm-12">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id={edu.value}
                  name="education"
                  value={edu.value}
                  checked={form.education.includes(edu.value)}
                  onChange={handleCheckboxChange}
                />
                <label className="form-check-label" htmlFor={edu.value}>
                  {edu.label}
                </label>
              </div>
            ))}
          </div>
        </div>
        {errors.education && <span style={{ color: "red", display: "block" }}>{errors.education}</span>}
      </div>
    </div>

    <div className="col-lg-12">
      {/* Category */}
      <div className="form-group">
        <h3>Category</h3>
        <div className="row">
          <div className="col-lg-6 col-sm-12">
            {categoryOptions.slice(0, Math.ceil(categoryOptions.length / 2)).map((category) => (
              <div key={category.value} className="form-check form-check-inline col-lg-12 col-sm-12">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id={category.value}
                  name="category"
                  value={category.value}
                  checked={form.category.includes(category.value)}
                  onChange={handleCheckboxChange}
                />
                <label className="form-check-label" htmlFor={category.value}>
                  {category.label}
                </label>
              </div>
            ))}
          </div>
          <div className="col-lg-6 col-sm-12">
            {categoryOptions.slice(Math.ceil(categoryOptions.length / 2)).map((category) => (
              <div key={category.value} className="form-check form-check-inline col-lg-12 col-sm-12">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id={category.value}
                  name="category"
                  value={category.value}
                  checked={form.category.includes(category.value)}
                  onChange={handleCheckboxChange}
                />
                <label className="form-check-label" htmlFor={category.value}>
                  {category.label}
                </label>
              </div>
            ))}
          </div>
        </div>
        {errors.category && <span style={{ color: "red", display: "block" }}>{errors.category}</span>}
      </div>
    </div>

    {/* <div className="col-lg-12">
      <div className="form-group">
        <h3>Boost Competition</h3>
        <div className="form-check">
          <input
            className="form-check-input"
            type="checkbox"
            id="boostCompetition"
            name="boostCompetition"
            checked={form.boostCompetition}
            onChange={() => setForm((prev) => ({ ...prev, boostCompetition: !prev.boostCompetition }))}
          />
          <label className="form-check-label" htmlFor="boostCompetition">
            Boost Competition
          </label>
        </div>
      </div>
    </div> */}
  </div>
)}


                  <div className="form-group mt-4">
                    {currentStep > 1 && (
                      <button type="button" className="btn btn-secondary" onClick={prevStep}>
                        Previous
                      </button>
                    )}
                    <button type="submit" className="btn btn-primary ms-2">
                      {currentStep === 3 ? "Submit" : "Next"}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
