import React, { useState } from "react";

export default function Faq() {
  const [activeIndex, setActiveIndex] = useState(null);

  // Static FAQ data organized into categories
  const faqs = [
    {
      category: "General Information",
      items: [
        {
          question: "What types of competitions are listed on this website?",
          answer: "We list competitions in various categories including Music, Creative Writing, Drama, Visual Arts, STEM, and Sports for school students aged 7 to 18."
        },
        {
          question: "Who can participate in these competitions?",
          answer: "These competitions are open to school students aged 7 to 18. Specific eligibility criteria may vary for each competition."
        },
        {
          question: "How can I find competitions suitable for my age group?",
          answer: "You can filter competitions by age group on our website to find those that are suitable for you."
        }
      ]
    },
    {
      category: "Registration and Participation",
      items: [
        {
          question: "How do I register for a competition?",
          answer: "To register, click on the competition you are interested in and follow the registration instructions provided on the competition page."
        },
        {
          question: "Is there a registration fee?",
          answer: "Some competitions may have a registration fee, while others are free. Please check the specific competition details for more information."
        },
        {
          question: "Can I participate in multiple competitions?",
          answer: "Yes, you can participate in as many competitions as you like, as long as you meet the eligibility criteria for each."
        }
      ]
    },
    {
        category: "Submission Guidelines",
        items: [
          {
            question: "How do I submit my entry?",
            answer: "Submission guidelines vary by competition. Detailed instructions will be provided on the competition page."
          },
          {
            question: "What formats are accepted for submissions?",
            answer: "Accepted formats depend on the competition category. For example, Music competitions may accept audio files, while Visual Arts competitions may require image files. Please refer to the specific competition guidelines."
          }
        ]
      },
      {
        category: "Judging and Results",
        items: [
          {
            question: "How are the entries judged?",
            answer: "Entries are judged based on criteria set by the competition organizers. This may include creativity, originality, technical skill, and adherence to the theme."
          },
          {
            question: "When will the results be announced?",
            answer: "Results announcement dates vary by competition. Please check the competition page for specific dates."
          },
          {
            question: "How will I be notified if I win?",
            answer: "Winners will be notified via email and the results will also be posted on our website."
          }
        ]
      },
      {
        category: "Support and Contact",
        items: [
          {
            question: "Who can I contact if I have questions or need help?",
            answer: "If you have any questions or need assistance, please contact our support team at info@thetalentpedia.com."
          }
        ]
      }
  ];

  const toggleAccordion = (categoryIndex, itemIndex) => {
    const index = `${categoryIndex}-${itemIndex}`;
    setActiveIndex(index === activeIndex ? null : index);
  };

  return (
    <>
      {/* Start Page Title Area */}
      <div className="banner-area faq">
        <div className="d-table">
          <div className="d-table-cell">
            <div className="container">
              <div className="page-title-content">
                <h2>FAQ</h2>
                <ul>
                  <li>
                    <a href="/"> Home </a>
                    <span class="straight-line">|</span>
                    <p className="active">FAQ</p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End Page Title Area */}

      {/* FAQ Section */}
      <div className="faq-area">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 faq-content">
              <div className="faq-accordion">
                <div className="section-title text-center">
                  <h2>Frequently Asked Questions</h2>
                </div>
                {faqs.map((category, categoryIndex) => (
                  <div key={categoryIndex}>
                    <br/>
                    <h3>{category.category}</h3>
                    <ul className="accordion">
                      {category.items.map((faq, itemIndex) => (
                        <li className="accordion-item" key={itemIndex}>
                          <a
                            className={`accordion-title ${
                              `${categoryIndex}-${itemIndex}` === activeIndex ? "active" : ""
                            }`}
                            onClick={() => toggleAccordion(categoryIndex, itemIndex)}
                          >
                            <i
                              className={`fa ${
                                `${categoryIndex}-${itemIndex}` === activeIndex ? "fa-minus" : "fa-plus"
                              }`}
                            ></i>{" "}
                            {faq.question}
                          </a>
                          <p
                            className={`accordion-content ${
                              `${categoryIndex}-${itemIndex}` === activeIndex ? "show" : "hide"
                            }`}
                          >
                            {faq.answer}
                          </p>
                        </li>
                      ))}
                    </ul>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End FAQ Section */}
    </>
  );
}
