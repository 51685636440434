import React, { useRef, useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import axios from "axios";
import Query from "./Query";
import { Front_API_URL } from "./Front/Constants";
import { Front_URL } from "./Front/constantsurl";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';




// import required modules
import { Pagination, Navigation, Scrollbar, A11y } from "swiper/modules";
import { Link } from "react-router-dom";
export default function Index() {
  const [banner, setBanner] = useState([]);
  const [category, setcategory] = useState([]);

  const fetchBanner = async () => {
    try {
      const response = await axios.get(`${Front_API_URL}/Banner`, {
      });
      setBanner(response.data);
    } catch (error) {
      console.error("Error fetching data", error);
    }
  };
  
  useEffect(() => {
    fetchBanner();
  }, []);
  const fetchCategory = async () => {
    try {
      const response = await axios.get(`${Front_API_URL}/category`, {
      });
      setcategory(response.data);
    } catch (error) {
      console.error("Error fetching data", error);
    }
  };

  useEffect(() => {
    fetchCategory();
  }, []);

  const iconMapping = {
    music: 'fa-music',
    'creative writing': 'fa-pen-nib',
    drama: 'fa-people-pulling',
    'visual arts':'fa-artstation',
    stem: 'fa-plant-wilt',
    languages: 'fa-language',
    sports: 'fa-basketball-ball',
  };
  
  function getCategoryIcon(category) {
    return iconMapping[category.toLowerCase()] || 'fa-question-circle'; 
  }

  return (
    <>
      <>
        <section className="slider-area">
        <Swiper
        style={{
          "--swiper-navigation-color": "#fff",
          "--swiper-pagination-color": "#fff",
        }}
        lazy={true}
        initialSlide={0}
        autoplay={{
            delay: 500,
            disableOnInteraction: false
        }}
        modules={[ A11y]}
        spaceBetween={50}
        className="mySwiper"
        data-no-preloader="true"
      >
        {banner.map && banner.map((item, index) => (
          <SwiperSlide key={index}> {/* Added key prop for each SwiperSlide */}
            <div
              className="single-slider single-slider-bg-1"
              style={{
                      height: "600px",
                      backgroundImage: `url(${Front_URL}/storage/images/${banner[0].image})`,
                      backgroundSize: "cover",
                      backgroundPosition: "center"
                  }}
            >
              <div className="d-table owl-item">
                <div className="d-table-cell">
                  <div className="container">
                    <div className="row align-items-center">
                      <div className="col-lg-12 text-center">
                        <div className="slider-tittle one">
                          <h1>
                            {item.heading}
                          </h1>
                          {/* <p>
                            {item.description}
                          </p> */}
                        </div>
                        <div className="slider-btn bnt1 text-center">
                          {/* <a href="#" className="box-btn">
                            About Us
                          </a> */}
                          {/* <a href="#" className="border-btn">
                            Contact Us
                          </a> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
        ))}
        </Swiper>

        </section>
      </>
      {/* <!-- End Slider aera --> */}

      <div className="shape-ellips">
        <img src="assets/images/shape.png" alt="shape" />
      </div>
      {/* all categories area */}
      <section className="home-ragular-course  pb-100 pt-100">
        <div className="container">
        <div className="section-tittle text-center ">
            <h2>Categories</h2>
          </div>

          <div className="row categories-row">
          {category.map && category.map((item, index) => (

            <div className="categories-column">
            
              <div className="single-ragular-categories">
                
                <Link to={`/events/${item.id}`} class="boxed-categories">
                <h2 > <i className={`fa-solid ${getCategoryIcon(item.name)}`}></i>{item.name}</h2>
                </Link> 
              </div>
            </div>
          ))}
          </div>
        </div>
      </section>
      {/* <!-- Regular Course area  --> */}

      <section className="home-ragular-course  pb-100">
        <div className="container">
          <div className="section-tittle text-center ">
            <h2>All Competitions</h2>
            <p>
              Join our competition and showcase your skills for a chance to win
              exciting prizes and recognition!"
            </p>
          </div>

          <div className="row">
          {category.map && category.map((item, index) => (

            <div className="col-lg-4 col-md-6">
              {/* <div className="single-ragular-course">
                <div className="course-img">
                  <img
                    src={`${Front_URL}/storage/images/${item.image}`}
                    alt="Competitions"
                  />
                  <h2>{item.name}</h2>
                </div>
                <div className="course-content">
                  <p>
                  {item.com_desc.substring(0, 176)}
                  </p>
                  <Link to={`/events/${item.id}`} className="border-btn">
                    View Details
                  </Link>
                </div>
              </div> */}
              <div className="single-ragular-course">
  <div className="course-img" style={{ height: "250px", overflow: "hidden" }}>
    <img
      src={`${Front_URL}/storage/images/${item.image}`}
      alt="Competitions"
      style={{ width: "100%", height: "100%", objectFit: "cover" }}
    />
    <h2>{item.name}</h2>
  </div>
  <div className="course-content">
    <p>{item.com_desc.substring(0, 176)}</p>
    <Link to={`/events/${item.id}`} className="border-btn">
      View Details
    </Link>
  </div>
</div>
            </div>
  ))}
          </div>
        </div>
      </section>

      {/* <!-- End Regular Course area  --> */}

      {/* <!-- Special Course --> */}
      {/* <section className="home-special-course">
        <div className="container-fluid">
          <div className="section-tittle text-center">
            <h2>
              Competitions 1<sup>st</sup> &nbsp; Winner
            </h2>
            <p>
              Winning a competition as a student can be a thrilling experience.
            </p>
          </div>

          <div className="home-course-slider owl-carousel owl-theme">
            <div className="single-home-special-course">
              <div className="course-img">
                <img src="assets/images/winner/1.png" alt="winner" />
                <div className="course-content">
                  <h2>Alexander</h2>
                  <p>Visual Art (Wimmer)</p>
                  <a href="#" className="box-btn">
                    Read More
                  </a>
                </div>
              </div>
            </div>

            <div className="single-home-special-course">
              <div className="course-img">
                <img src="assets/images/winner/2.png" alt="winner" />
                <div className="course-content">
                  <h2>Jacob</h2>
                  <p>Music (Winner)</p>
                  <a href="#" className="box-btn">
                    Read More
                  </a>
                </div>
              </div>
            </div>

            <div className="single-home-special-course">
              <div className="course-img">
                <img src="assets/images/winner/3.png" alt="winner" />
                <div className="course-content">
                  <h2>Sophia</h2>
                  <p>Creative Writing(Winner)</p>
                  <a href="#" className="box-btn">
                    Read More
                  </a>
                </div>
              </div>
            </div>

            <div className="single-home-special-course">
              <div className="course-img">
                <img src="assets/images/winner/4.png" alt="winner" />
                <div className="course-content">
                  <h2>Michael</h2>
                  <p>Stem (Winner)</p>
                  <a href="#" className="box-btn">
                    Read More
                  </a>
                </div>
              </div>
            </div>
            <div className="single-home-special-course">
              <div className="course-img">
                <img src="assets/images/winner/5.png" alt="winner" />
                <div className="course-content">
                  <h2>Elizabeth</h2>
                  <p>
                    <p>Drama (Winner)</p>
                  </p>
                  <a href="#" className="box-btn">
                    Read More
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      {/* <!-- End Special Course --> */}

      {/* <!-- Contact Area --> */}
       {/* <Query /> */}
     
      {/* <!-- End Navbar Area --> */}
    </>
  );
}
