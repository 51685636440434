import axios from "axios";
import Query from "./Query";
import React, { useEffect, useState } from "react";
import { Front_API_URL } from "./Front/Constants";

export default function ContactUs(){
    const [formData, setFormData] = useState({
        title: "",
        address: "",
        helpCenter: "",
        whatsappNumber: "",
        email: "",
        facebookUrl: "",
        twitterUrl: "",
        instagramUrl: "",
        logo :"",
      });

      useEffect(() => {
        const fetchData = async () => {
          try {
            const response = await axios.get(
              `${Front_API_URL}/frontShowComapanySetting`
            );
            setFormData({
              title: response.data.data.title,
              address: response.data.data.address,
              helpCenter: response.data.data.help_center,
              whatsappNumber: response.data.data.whatspp_num,
              email: response.data.data.email_support,
              facebookUrl: response.data.data.facebook,
              twitterUrl: response.data.data.twitter,
              instagramUrl: response.data.data.instagram,
              logo: response.data.data.logo,

            });
          } catch (err) {
            console.error("Error fetching data", err);
          }
        };
    
        fetchData();
      }, []);
    return (
        <>
        
{/* <!-- Start Page Title Area --> */}
<div class="banner-area contact">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>Contact Us</h2>
                    <ul>
                        <li>
                            <a href="/"> Home </a>
                            <span class="straight-line">|</span>
                            <p class="active">Contact</p>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
{/* <!-- End Page Title Area --> */}

<div class="shape-ellips-contact">
    <img src="assets/images/contact-shape.png" alt="shape" />
</div>

{/* <!-- Service area --> */}
<div class="contact-service">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-sm-6">
                <div class="single-service text-center">
                    <div class="service-icon">
                        <i class="flaticon-clock"></i>
                    </div>
                    <div class="service-content">
                        <p>Mon - Sat (9:00 am - 6:00 pm)</p>
                    </div>
                </div>
            </div>
            {/* <div class="col-lg-4 col-sm-6">
                <div class="single-service text-center">
                    <div class="service-icon">
                        <i class="flaticon-pin"></i>
                    </div>
                    <div class="service-content">
                        <p>{formData.address}</p>
                    </div>fas fa-envelope
                </div>
            </div> */}
            <div class="col-lg-4 col-sm-6">
    <div class="single-service text-center">
        <div class="service-icon">
            <i class="fas fa-envelope"></i> {/* Optionally, use an email-related icon */}
        </div>
        <div class="service-content">
            <p>{formData.email}</p>
        </div>
    </div>
</div>

            <div class="col-lg-4 col-sm-12">
                <div class="single-service text-center sst-10">
                    <div class="service-icon">
                        <i class="flaticon-telephone"></i>
                    </div>
                    <div class="service-content">
                        <p>{formData.helpCenter}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

{/* <!-- End Service area --> */}

{/* <!-- Contact Area --> */}
<Query />
{/* <!-- End Contact Area --> */}
        </>
    )
}